import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ProfileUserLinks from "./ProfileUserLinks";

import MdCancel from "react-ionicons/lib/MdClose";
import MdList from "react-ionicons/lib/MdList";
import MdLogOut from "react-ionicons/lib/MdLogOut";
import { signOut } from "../../api/Auth";
import {
  ADMIN_CONNECTIONS_PATH,
  ADMIN_DISTRIBUTORS_PATH,
  ADMIN_HOME_PATH,
  ADMIN_MY_ACCOUNT_PATH,
  ADMIN_PAYMENTS_PATH,
  ADMIN_TOOLS_PATH,
} from "../../constants/adminPaths";
import {
  DISTRIBUTOR_DASHBOARD_PATH,
  DISTRIBUTOR_HOME_PATH,
  DISTRIBUTOR_MY_ACCOUNT_PATH,
} from "../../constants/distributorPaths";
import {
  APP_HOME_PATH,
  APP_MY_ACCOUNT_PATH,
  APP_SEARCH_FOLIO_FACTURA,
  APP_WFACTURAS_PATH,
  INSTALL_PATH,
} from "../../constants/paths";
import { NotificationContext } from "../../context/NotificationContext";
import { useForm } from "react-hook-form";
import { getUserData } from "../../api/User";
import { useTranslation } from "react-i18next";
import "./SignedInLinks.scss";

const SignedInLinks = ({ admin, distributor }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState({});

  // Instantiate form
  const { reset } = useForm({
    defaultValues: userData,
  });

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  useEffect(() => {
    if (success) setUserData(getUserData());
  }, [success]);

  useEffect(() => {
    reset(userData);
  }, [userData]);

  const handleSignOut = () => {
    if (
      window.confirm(
        "¿Seguro que deseas cerrar sesión? \nEsto te permitirá iniciar sesión en otro navegador"
      )
    ) {
      signOut(userData.email);
    }
  };

  if (admin) {
    return (
      <>
        <ul className="md:flex hidden pl-4 text-base items-center font-regular">
          <li>
            <Link to={ADMIN_HOME_PATH}>{t("navBar.users")}</Link>
          </li>
          <li className="md:ml-4">
            <Link
              to={ADMIN_CONNECTIONS_PATH}
              className="notificationBadgeWrapper"
            >
              {t("navBar.conn")}
            </Link>
          </li>
          <li className="md:ml-4">
            <Link to={ADMIN_TOOLS_PATH}>{t("navBar.tools")}</Link>
          </li>
          <NavLink
            to=""
            exact
            activeClassName="active"
            className="px-4 py-2 flex rounded-md m-2 text-gray-700"
            onClick={handleSignOut}
          >
            <div className="logout-button">
              <MdLogOut className="fill-current" />
              <span className="hover-helper-text">{t("navBar.signOut")}</span>
            </div>
          </NavLink>
        </ul>
        <ul className="list-no-show md:flex block pl-4 text-base items-center font-regular">
          <li className={`menu-button-burger ${show ? "show" : ""}`}>
            {!show ? (
              <MdList
                style={{ float: "right", marginRight: "1rem" }}
                onClick={() => setShow(!show)}
              />
            ) : (
              <MdCancel
                style={{ float: "right", marginRight: "1rem" }}
                onClick={() => setShow(!show)}
              />
            )}
            <ProfileUserLinks
              show={show}
              setShow={setShow}
              admin={admin}
              distributor={distributor}
            />
          </li>
        </ul>
      </>
    );
  }

  if (distributor) {
    return (
      <ul className="md:flex hidden pl-4 text-base items-center font-regular">
        <li>
          <Link to={DISTRIBUTOR_DASHBOARD_PATH}>{t("navBar.dash")}</Link>
        </li>
        <li className="md:ml-4">
          <Link to={DISTRIBUTOR_MY_ACCOUNT_PATH}>{t("navBar.myAcc")}</Link>
        </li>
      </ul>
    );
  }

  return (
    <>
      <ul className="md:flex hidden pl-4 text-base items-center font-regular">
        <li>
          <Link to={INSTALL_PATH}>{t("navBar.tutorials")}</Link>
        </li>
        <li className="md:ml-4">
          <Link to={APP_HOME_PATH}>{t("navBar.rest")}</Link>
        </li>
        <li className="md:ml-4">
          <Link to={APP_MY_ACCOUNT_PATH}>{t("navBar.myAcc")}</Link>
        </li>
        <li className="md:ml-4">
          <Link to={APP_SEARCH_FOLIO_FACTURA}>WFacturas</Link>
        </li>
      </ul>
      <ul className="list-no-show md:flex block pl-4 text-base items-center font-regular">
        <li className={`menu-button-burger ${show ? "show" : ""}`}>
          {!show ? (
            <MdList
              style={{ float: "right", marginRight: "1rem" }}
              onClick={() => setShow(!show)}
            />
          ) : (
            <MdCancel
              style={{ float: "right", marginRight: "1rem" }}
              onClick={() => setShow(!show)}
            />
          )}
          <ProfileUserLinks admin={admin} distributor={distributor} />
        </li>
      </ul>
    </>
  );
};

export default SignedInLinks;
