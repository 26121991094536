import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import NewFacturaFormLanding from "./Components/NewFacturaFormLanding";
import NotFoundPage from "./NotFoundPage";
import { getProfilePicKey } from "../../api/User";
import wmobilFacturasLogo from "../../images/wmobilfacturacion.svg";

const isInteger = (value) => {
  return /^\d+$/.test(value);
};

const FacturacionPage = ({ match }) => {
  const { t } = useTranslation();
  const restId = match ? match.params.id : null;
  const [restaurantLogoUrl, setRestaurantLogoUrl] =
    useState(wmobilFacturasLogo);

  useEffect(() => {
    if (restId) {
      getProfilePicKey(restId)
        .then((key) => {
          if (key) {
            const fullUrl = `https://wmobil.nyc3.digitaloceanspaces.com/${key}`;
            setRestaurantLogoUrl(fullUrl);
          }
        })
        .catch((error) => {
          console.error("Error fetching restaurant logo:", error);
        });
    }
  }, [restId]);

  if (restId && !isInteger(restId)) {
    return <NotFoundPage />;
  }

  return (
    <LayoutV2 title="Inicio">
      <div className="custom-factura-form-container">
        <div className="card-search-container">
          <img
            className="w-full md:w-1/3 rounded mb-4"
            src={restaurantLogoUrl}
            alt="wmobil facturas"
          />
          <label className="step-indicator">Paso 1 de 2</label>
          <h2 className="search-text mb-4">Busca tu folio para facturar</h2>
          <NewFacturaFormLanding restId={restId} picKey={restaurantLogoUrl} />
        </div>
      </div>
    </LayoutV2>
  );
};

export default FacturacionPage;
