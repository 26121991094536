import React, { useState, useEffect } from "react";
import { deleteDatabase, fetchRestaurant } from "../../api/Admin";
import BackArrow from "../../components/BackArrow";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import { ADMIN_USERS_PATH } from "../../constants/adminPaths";
import { STRIPE_SUBSCRIPTION_PATH } from "../../constants/secrets";

import MdLink from "react-ionicons/lib/MdLink";

import dayjs from "dayjs";
import "dayjs/locale/es"; // load on demand
import DotComponent from "../../components/DotComponent";
import { useTranslation } from "react-i18next";

const AdminRestaurantPage = ({ match }) => {
  const { t } = useTranslation();
  // Variables for handling restaurant data from API.
  const [restaurant, setRestaurant] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get restaurant
    fetchRestaurant(match.params.id)
      .then((restaurants) => {
        setRestaurant(restaurants[0]);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleDeleteDatabase = () => {
    if (
      window.confirm(
        `Estás seguro que deseas borrar la base de datos para ${restaurant.name} (${restaurant.dbString})?`
      )
    ) {
      deleteDatabase(restaurant.dbString).then((message) => {
        alert(message);
      });
    }
  };

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  return (
    <Layout title={`${restaurant.name}`} admin>
      <div className="py-8 wrapper main-admin-page-padding">
        <BackArrow
          to={`${ADMIN_USERS_PATH}/${match.params.user_id}`}
          title={t("installPage.backBtn")}
        ></BackArrow>
        <div className="flex justify-between items-end text-gray-700">
          <div>
            <h2 className="text-4xl text-gray-800 font-bold leading-tight">
              {restaurant.name}
            </h2>{" "}
            <p className="text-sm mb-4">
              {t("admin.userProfLabel7")}{" "}
              {dayjs(restaurant.created_at)
                .locale(t("admin.lan"))
                .format(
                  `dddd D [${t("admin.userProfLabel2")}] MMMM [${t(
                    "admin.userProfLabel3"
                  )}] YYYY`
                )}
            </p>
            <DotComponent status={restaurant.active}></DotComponent>
          </div>
          <a
            href={`${STRIPE_SUBSCRIPTION_PATH}/${restaurant.stripe_id}`}
            target="_blank"
            className="flex items-center text-gray-700 text-base"
          >
            <MdLink className="fill-current mr-1 inline-block w-6"></MdLink>
            {t("admin.userProfLabel6")}
          </a>
        </div>
        <hr className="mt-2 mb-4"></hr>
        <h3 className="uppercase font-bold text-sm text-gray-700">
          {t("admin.actions")}
        </h3>
        <ul className="mt-2">
          <li
            className="cursor-pointer text-red-500 inline-block"
            onClick={handleDeleteDatabase}
          >
            {t("admin.deleteMsg")}
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default AdminRestaurantPage;
